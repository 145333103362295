import {
    Container,
    Row,
    Col,
    List,
    ListGroupItem
} from "reactstrap";
import styles from './styles.module.scss';
import Link from 'next/link';
import Linkedin from '../../public/site-assets/svg/linkedin.svg'
import Facebook from '../../public/site-assets/svg/facebook.svg'
import Twitter from '../../public/site-assets/svg/twitter.svg'
import Instagram from '../../public/site-assets/svg/instagram.svg'

export default function SocialLinks({
    customClass = ""
}) {
    return (
        <div className={`${styles.root} ${customClass}`}>
            <List className="social-links-wrapper d-inline-flex flex-wrap p-0 m-0">
                <ListGroupItem className="me-2 mb-2">
                    <Link href="https://www.linkedin.com/company/11546/">
                        <a className="text-decoration-none" target="_blank" rel="noreferrer noopener" aria-label="Follow us on Linkedin">
                            <Linkedin />
                        </a>
                    </Link>
                </ListGroupItem>
                {/* <ListGroupItem className="me-2 mb-2">
                    <Link href="https://twitter.com/BartechGroup">
                        <a className="text-decoration-none" target="_blank" rel="noreferrer noopener" aria-label="Follow us on Twitter">
                            <Twitter />
                        </a>
                    </Link>
                </ListGroupItem> */}
                {/* <ListGroupItem className="me-2 mb-2">
                    <Link href="https://www.facebook.com/bartechgroup">
                        <a className="text-decoration-none" target="_blank" rel="noreferrer noopener" aria-label="Follow us on Facebook">
                            <Facebook />
                        </a>
                    </Link>
                </ListGroupItem> */}
                {/* <ListGroupItem className="me-2 mb-2">
                    <Link href="https://www.instagram.com/lorienglobal/">
                        <a className="text-decoration-none" target="_blank" rel="noreferrer noopener" aria-label="Follow us on Instagram">
                            <Instagram />
                        </a>
                    </Link>
                </ListGroupItem> */}
            </List>
        </div>
    )
}
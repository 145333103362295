import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Row,
    Col,
    Card,
    CardGroup,
    Nav,
    NavItem,
    CardBody
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import Logo from "/public/site-assets/svg/logo-black.svg"

import ImpellamGroup from "/public/site-assets/svg/impellam-group.svg"
import React, {
    useRef,
    useEffect,
    useState
} from "react";
import { useRouter } from 'next/router';
import Sourceflow from '/public/sourceflow_logo.svg';
import SocialLinks from '/components/SocialLinks';
import FooterCopyrightNavigations from '../../.sourceflow/footer_copyright_navigations.json';
import FooterAboutLorienNavigations from '../../.sourceflow/footer_about_lorien_navigations.json';
import FooterJobSeekersNavigations from '../../.sourceflow/footer_job_seekers_navigations.json';
import FooterDocumentsNavigations from '../../.sourceflow/footer_documents_navigations.json';
import FooterSolutionsnNavigations from '../../.sourceflow/solutions.json';
import FooterInsightsNavigations from '../../.sourceflow/footer_insights_navigations.json';

export default function MainFooter({
    global,
    pathPrefix,
    customClass = "",
}) {
    const router = useRouter();

    return (
        <section className={`${styles.root} ${customClass} position-relative`}>
            {/* <div className="reciteWrapper py-2 bg-white">
                <Container>
                    <button className="recite-btn p-0" id="reciteEnable" aria-label="Accessibility and Languages">Accessibility and Languages</button>
                </Container>
            </div> */}

            <Container>
                <hr></hr>
                <div className="pt-3">
                    <Link href="/">
                        <a aria-label="Logo" title="Bartech">
                            <Logo />
                        </a>
                    </Link>
                    <Row className="mt-4">
                        <Col md={12}>
                            <Row className="justify-content-between">
                                <Col md={6} lg={2}>
                                    <div className="mb-4 navigations">
                                        <p className="mb-1">Get in Touch</p>
                                        <p className="mb-0">
                                            <Link href="tel:8008242962">
                                                <a>800.824.2962</a>
                                            </Link>
                                        </p>
                                        <p>
                                            <Link href="mailto:connect@bartechgroup.com">
                                                <a>connect@bartechgroup.com</a>
                                            </Link>
                                        </p>
                                    </div>
                                    <SocialLinks customClass="social-links w-100 mb-5 d-flex align-items-end" />
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-1">For Job Seekers</p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterJobSeekersNavigations.category_values.map((post, index) => (
                                        <li key={index}>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link?.en}`}>
                                                <a className="text-decoration-none">
                                                    {post.title.en}
                                                </a>
                                            </Link>
                                        </li>
                                        ))}
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-3"><b>Business Solutions</b></p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterSolutionsnNavigations.category_values.map((post, index) => (
                                        <li key={index}>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/business-solutions/${post.url_slug}/`}>
                                                <a className="text-decoration-none">
                                                    {post.title.en}
                                                </a>
                                            </Link>
                                        </li>
                                        ))}

                                        <li className="mt-3 mb-2">
                                            {/* <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/business-solutions`}>
                                                <a> */}
                                                    <b>Our Markets</b>
                                                {/* </a>
                                            </Link> */}
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors/automotive`}>
                                                <a>
                                                    <strong>Automotive</strong>
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors/manufacturing-and-commercial`}>
                                                <a>
                                                    <strong>Manufacturing</strong>
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors/utilities`}>
                                                <a>
                                                    <strong>Utilities</strong>
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors/oil-and-gas`}>
                                                <a>
                                                    <strong>Oil & Gas</strong>
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors/retail-consumer-products`}>
                                                <a>
                                                    <strong>Consumer Products</strong>
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors/supply-chain-and-logistics`}>
                                                <a>
                                                    <strong>Supply Chain & Logistics</strong>
                                                </a>
                                            </Link>
                                        </li>   
                                        <li>
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/sectors/technology-equipment-and-services`}>
                                                <a>
                                                    <strong>Technology Equipment & Services</strong>
                                                </a>
                                            </Link>
                                        </li>

                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-3"><b>About Bartech Staffing</b></p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterAboutLorienNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link?.en}`}>
                                                    <a className="text-decoration-none">
                                                        {post.title.en}
                                                    </a>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-3"><b>Insights</b></p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterInsightsNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                <Link href={`${post.link.en}`}>
                                                    <a className="text-decoration-none">
                                                        {post.title.en}
                                                    </a>
                                                </Link>
                                            </li>
                                        ))}
                                        {/* <li >
                                            <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}/insights/`}>
                                                <a className="text-decoration-none">
                                                    Insights
                                                </a>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </Col>
                                <Col md={6} lg={2}>
                                    <p className="mb-3"><b>Documents</b></p>
                                    <ul className="list-unstyled navigations" >
                                        {FooterDocumentsNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                {post?.new_tab?.en ?
                                                    <Link href={`${post.link?.en}`}>
                                                        <a className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                                                            {post.title.en}
                                                        </a>
                                                    </Link> :
                                                    <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link?.en}`}>
                                                        <a className="text-decoration-none">
                                                            {post.title.en}
                                                        </a>
                                                    </Link>}
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-start mt-4">
                    <span className="impellem-group d-flex align-items-end me-4">
                        <small>Part Of</small>
                        <Link href="https://www.impellam.com/">
                            <a target="_blank" rel="noopener noreferrer" title="ImpellAm Group" className="ms-1">
                                <ImpellamGroup /></a></Link>
                    </span>
                    <div className="my-4 my-md-0">
                        <ul className="d-flex flex-wrap list-unstyled footer-navigations-2" >
                            {FooterCopyrightNavigations.category_values.map((post, index) => (
                                <li className="me-md-3 mb-1 pe-2 pe-md-0" key={index}>
                                    {post.new_tab ?
                                        <Link href={`${post.link?.en}`}>
                                            <a className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                                                <small>{post.title?.en}</small>
                                            </a>
                                        </Link> :
                                        <Link href={`${router.pathname.startsWith("/us") ? "/us" : ""}${post.link?.en}`}>
                                            <a className="text-decoration-none">
                                                <small>{post.title?.en}</small>
                                            </a>
                                        </Link>}

                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="text-nowrap d-flex align-items-end">
                        <p className="mb-0 me-3"><small>© {new Date().getFullYear()} Bartech Staffing.</small></p>
                        <p className="mb-0 me-1"><small>Site by</small></p>
                        <Link href="https://www.sourceflow.co.uk/">
                            <a className="sourceflow" rel="noopener noreferrer" target="_blank" title="SourceFlow">
                                <Sourceflow />
                            </a>
                        </Link>
                    </div>
                </div>
            </Container>
        </section>
    )
}